import React, { useEffect, useState } from 'react';
import DayPicker, { ClassNames, Modifier } from 'react-day-picker';
import styles from '../styles/components/Calendar.module.css';
import Text from './Text';
import { NavbarElementProps } from 'react-day-picker/types/props';
import { OneDayTimeSlot, TimeSlots } from '../model/schedule';
import Loading from './Loading';
import { getAvailableTimeSlots } from '../rest/schedule';
import { getErrorMessage } from '../helpers/error';
import { addMonths, isSameDay } from 'date-fns';

function getNavbar(
  addressId: number,
  month: Date,
  setMonth: (month: Date) => void,
): React.FC<NavbarElementProps> {
  return () => {
    return (
      <div className={styles.navBar}>
        <span
          tabIndex={0}
          role="button"
          aria-label="Previous Month"
          className={styles.navButtonPrev}
          onClick={async () => {
            setMonth(addMonths(month, -1));
          }}
        >
          ←
        </span>
        <span
          tabIndex={0}
          role="button"
          aria-label="Next Month"
          className={styles.navButtonNext}
          onClick={async () => {
            setMonth(addMonths(month, 1));
          }}
        >
          →
        </span>
      </div>
    );
  };
}

export interface CalendarProps {
  addressId: number;
  jobId: number;
  onSelect: (day?: OneDayTimeSlot) => void;
  onError: (error?: JSX.Element) => void;
}

const Calendar: React.FC<CalendarProps> = ({
  addressId,
  jobId,
  onSelect,
  onError,
}) => {
  const [selectedDay, setSelectedDay] = useState<Date>();
  const [loading, setLoading] = useState(false);
  const [timeSlots, setTimeSlots] = useState<TimeSlots>();
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    let ignore = false;

    async function load() {
      try {
        onSelect(undefined);
        onError(undefined);
        setLoading(true);
        const opts = await getAvailableTimeSlots(addressId, jobId, date);
        if (!ignore) {
          setTimeSlots(opts);
        }
      } catch (e) {
        if (!ignore) {
          onError(getErrorMessage(e));
        }
      } finally {
        if (!ignore) {
          setLoading(false);
        }
      }
    }

    load();

    return () => {
      ignore = true;
    };
  }, [addressId, jobId, date, onError, onSelect]);

  if (loading) {
    return (
      <div className={styles.calendar}>
        <Loading/>
      </div>
    );
  }

  const today = new Date();
  const modBeforeToday: Modifier = { before: today };
  const modAfterToday: Modifier = { after: today };

  const available = timeSlots
    ? timeSlots
      .filter(o => o.slots.filter(w => w.available).length > 0)
      .map(o => new Date(o.date))
    : [];

  const nonServiceable = timeSlots
    ? timeSlots
      .filter(o => o.slots.length === 0)
      .map(o => new Date(o.date))
    : [];

  return (
    <div className={styles.calendar}>
      <DayPicker
        month={date}
        classNames={styles as unknown as ClassNames}
        disabledDays={[
          modBeforeToday,
        ]}
        weekdaysShort={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
        navbarElement={getNavbar(addressId, date, setDate)}
        selectedDays={selectedDay}
        modifiers={{
          [styles.selected]: selectedDay,
          [styles.today]: today,
          [styles.beforeToday]: modBeforeToday,
          [styles.afterToday]: modAfterToday,
          [styles.available]: (date) => !!available.find(d => isSameDay(d, date)),
          [styles.nonServiceable]: (date) => !!nonServiceable.find(d => isSameDay(d, date))
        }}
        onDayClick={(day, modifiers) => {
          if (modifiers[styles.disabled] || modifiers[styles.beforeToday] || modifiers[styles.nonServiceable]) {
            // Unselect everything when clicking on a disabled day
            setSelectedDay(undefined);
            onSelect(undefined);
            return;
          }
          if (modifiers[styles.selected]) {
            // Unselect the day which is already selected
            setSelectedDay(undefined);
            onSelect(undefined);
            return;
          }
          setSelectedDay(day);
          onSelect(timeSlots && timeSlots[day.getDate() - 1]);
        }}
        onMonthChange={setDate}
      />
      <div className={styles.legend}>
        <div className={styles.legendItem}>
          <div className={styles.legendIconChosen}></div>
          <Text size="small">Chosen</Text>
        </div>
        <div className={styles.legendItem}>
          <div className={styles.legendIconAvailable}></div>
          <Text size="small">Available</Text>
        </div>
        <div className={styles.legendItem}>
          <div className={styles.legendIconUnavailable}></div>
          <Text size="small">Unavailable</Text>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
