import React from 'react';
import styles from '../styles/pages/Connect.module.css';
import StepperPage from './StepperPage';
import Text from '../components/Text';
import { ContactInfoForm } from '../components/form/ContactInfoForm';
import { getAddressInfo } from '../helpers/store';
import { useHistory } from 'react-router-dom';
import { paths } from '../App';

const Connect1: React.FC = () => {
  const history = useHistory();
  const addressInfo = getAddressInfo();

  if (!addressInfo) {
    history.push(paths.home);
    return null;
  }

  return  (
    <StepperPage
      step={1}
      className={styles.connect}
      title="Let's get started"
      isChatShown={true}
    >
      <Text font="larsseit" size="big" align="center">
        <p>We’ll just need a little information to set up your account</p>
      </Text>
      <ContactInfoForm
        addressInfo={addressInfo}
        onBack={() => history.push(paths.available)}
        onNext={() => history.push(paths.connect2)}
      />
    </StepperPage>
  );
};

export default Connect1;
