import React from 'react';
import styles from '../styles/pages/StepperPage.module.css';
import SignupPage, { SignupPageProps } from './SignupPage';
import Stepper, { StepperProps } from '../components/Stepper';

export type StepperPageProps = SignupPageProps & Pick<StepperProps, 'step'>;

const StepperPage: React.FC<StepperPageProps> = ({
  step,
  children,
  centerVertically = false,
  className,
  ...props
}) => {
  return (
    <div className={[className, centerVertically ? styles.centerVertically : ''].join(' ')}>
      <Stepper steps={3} step={step}/>
      <SignupPage {...props} centerVertically={centerVertically}>
        {children}
      </SignupPage>
    </div>
  );
};

export default StepperPage;
