import React from 'react';
import styles from '../styles/components/Stepper.module.css';
import _ from 'lodash';
import check from '../assets/check-white.svg';
import Text from './Text';

export interface StepperProps {
  steps: number;
  step: number | 'complete';
}

const Stepper: React.FC<StepperProps> = ({ steps, step }) => {
  return (
    <div className={styles.stepper}>
      {_.range(1, steps + 1).map(s => {
        const isDone = step === 'complete' || step > s;
        const isActive = step === s;
        const className = isDone ? styles.stepPrevious : isActive ? styles.stepCurrent : styles.stepNext;
        return (
          <div key={s} className={className} aria-hidden={true}>
            {isDone
              ? <img src={check} alt="Check mark"/>
              : <Text font="sofia" className={styles.text}>{s}</Text>}
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
