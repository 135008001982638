import React, { useState } from 'react';
import { useField, FieldAttributes } from 'formik';
import textFieldStyles from '../../styles/components/form/TextField.module.css';
import styles from '../../styles/components/form/CheckboxField.module.css';
import { TextFieldProps } from './TextField';
import _ from 'lodash';

export interface CheckboxFieldProps extends Omit<TextFieldProps, 'label' | 'placeholder'> {
  label: string | JSX.Element;
  labelSize?: 'normal' | 'small';
  checked?: boolean;
  onCheckedChange?: (checked: boolean) => void;
}

const CheckboxField: React.FC<FieldAttributes<CheckboxFieldProps>> = props => {
  const [isChecked, setIsChecked] = useState(props.checked || false);
  const [field, meta, helpers] = useField(props);
  const {
    label,
    labelSize,
    checked,
    onCheckedChange,
    bold,
    ...inputProps
  } = props;
  const withError = meta.touched && meta.error;
  const size = labelSize || 'normal';
  return (
    <div className={textFieldStyles.field}>
      <label
        className={size === 'normal' ? styles.label : textFieldStyles.label}
        htmlFor={field.name}
      >
        <input
          type="checkbox"
          id={props.name}
          checked={checked}
          className={styles.checkbox}
          {...field}
          onChange={e => {
            setIsChecked(e.target.checked);
            onCheckedChange && onCheckedChange(e.target.checked);
            field.onChange(e);
          }}
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          {...(inputProps as any)}
        />
        <span className={styles.container}>
          <span
            className={isChecked ? styles.checked : styles.unchecked}
            role="checkbox"
            aria-checked={isChecked}
            tabIndex={0}
            onKeyPressCapture={e => {
              if (_.includes([' ', 'enter'], e.key.toLowerCase())) {
                setIsChecked(!isChecked);
                onCheckedChange && onCheckedChange(!isChecked);
                helpers.setValue(!isChecked);
              }
            }}
          ></span>
          <span className={styles.text}>{label}</span>
        </span>
      </label>
      <div className={styles.error}>{withError ? meta.error : ''}&nbsp;</div>
    </div>
  );
};

export default CheckboxField;
