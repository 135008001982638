import axios, { AxiosRequestConfig, AxiosInstance, AxiosError } from 'axios';
import config from '../config';

export enum ErrorCategory {
  Api = 'Api',
  Backend = 'Backend',
  Frontend = 'Frontend',
}

export enum ErrorReason {
  NoErrorResponseData = 'NoErrorResponseData',
  NoResponseReceived = 'NoResponseReceived',
  BadRequestSettings = 'BadRequestSettings',
}

export class RestError extends Error {
  public constructor(
    readonly category: ErrorCategory,
    readonly reason: ErrorReason,
    readonly description: string
  ) {
    super(description);
  }
}

export interface SimpleRestError {
  msg: string;
  code: number;
}

const restConfig: AxiosRequestConfig = {
  baseURL: config.baseUrl,
  timeout: 15000,
  withCredentials: true,
  headers: { // Default headers for JSON-based API
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf8',
  },
};

const client: AxiosInstance = axios.create(restConfig);

export async function api<T>(url: string, params?: AxiosRequestConfig): Promise<T> {
  const response = await client(url, params).catch((error: AxiosError) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.data) {
        // eslint-disable-next-line no-throw-literal
        throw error.response.data as RestError;
      } else {
        throw new RestError(
          ErrorCategory.Frontend,
          ErrorReason.NoErrorResponseData,
          `Received an error with no error data in response body. Response status: ${error.response.status}`
        );
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      throw new RestError(
        ErrorCategory.Frontend,
        ErrorReason.NoResponseReceived,
        'The request was made but no response was received'
      );
    } else {
      throw new RestError(
        ErrorCategory.Frontend,
        ErrorReason.BadRequestSettings,
        `Something happened in setting up the request that triggered an error: ${error.message}`
      );
    }
  });

  return response.data as Promise<T>;
}
