import {api} from './client';
import {TimeSlots, GetTimeSlotsRequest, ScheduleJobRequest} from '../model/schedule';

export async function getAvailableTimeSlots(addressId: number, jobId: number, date: Date): Promise<TimeSlots> {
  const request = {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    addressId: addressId,
    jobId: jobId,
  } as GetTimeSlotsRequest
  return api<TimeSlots>(`/schedule/time-slots`, {
      method: 'POST',
      data: JSON.stringify(request),
    },
  );
}

export async function scheduleJob(request: ScheduleJobRequest): Promise<void> {
  return api<void>('/schedule/job', {
    method: 'POST',
    data: JSON.stringify(request),
  });
}
