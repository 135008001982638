/// <reference types="googlemaps" />
import {AddressInfo, AddressValidationResponse, ServicePlan} from '../model/address';
import {CreateAccountRequest, SonarAccountInfo} from '../model/account';
import {ScheduleJobRequest} from "../model/schedule";

enum Keys {
  AddressInfo = 'AddressInfo',
  AddressValidation = 'AddressValidation',
  Schedule = 'Schedule',
  AccountDetails = 'AccountDetails',
  SonarAccountInfo = 'SonarAccountInfo',
  GeocoderResult = 'GeocoderResult',
  ServicePlan = 'ServicePlan',
}

const prefix = 'NB::';

function get<T>(key: Keys): T | null {
  const value = sessionStorage.getItem(prefix + key);
  if (value) {
    return JSON.parse(value) as T;
  }
  return null;
}

function set(key: Keys, value: any) {
  if (value !== null && value !== undefined) {
    sessionStorage.setItem(prefix + key, JSON.stringify(value));
  }
}

export function setAddressInfo(value: AddressInfo) {
  set(Keys.AddressInfo, value);
}

export function getAddressInfo(): AddressInfo | null {
  return get<AddressInfo>(Keys.AddressInfo);
}

export function setAddressValidation(value: AddressValidationResponse) {
  set(Keys.AddressValidation, value);
}

export function getAddressValidation(): AddressValidationResponse | null {
  return get<AddressValidationResponse>(Keys.AddressValidation);
}

export function setServicePlan(value: ServicePlan) {
  set(Keys.ServicePlan, value);
}

export function getServicePlan(): ServicePlan | null {
  return get<ServicePlan>(Keys.ServicePlan);
}

export function setAccountDetails(value: CreateAccountRequest) {
  set(Keys.AccountDetails, value);
}

export function getAccountDetails(): CreateAccountRequest | null {
  return get<CreateAccountRequest>(Keys.AccountDetails);
}

export function setGeocoderResult(value: google.maps.GeocoderResult) {
  set(Keys.GeocoderResult, value);
}

export function getSonarAccountInfo(): SonarAccountInfo | null {
  return get<SonarAccountInfo>(Keys.SonarAccountInfo);
}

export function setSonarAccountInfo(value: SonarAccountInfo) {
  set(Keys.SonarAccountInfo, value);
}

export function getGeocoderResult(): google.maps.GeocoderResult | null {
  return get<google.maps.GeocoderResult>(Keys.GeocoderResult);
}

export function setSchedule(value: ScheduleJobRequest) {
  set(Keys.Schedule, value);
}

export function getSchedule(): ScheduleJobRequest | null {
  return get<ScheduleJobRequest>(Keys.Schedule);
}
