import React from 'react';
import _ from "lodash";
import styles from '../styles/components/PlanPanel.module.css';
import Button from '../components/form/Button';
import Text from './Text';

export interface PlanPanelProps {
  speedMbps: number;
  speedSpecial?: boolean;
  price: number;
  paymentInfo: string;
  paymentInfoSpecial?: string;
  special?: boolean;
  onChoose: () => void;
  loading: boolean;
}

const PlanPanel: React.FC<PlanPanelProps> = ({
  speedMbps,
  speedSpecial = false,
  price,
  paymentInfo,
  paymentInfoSpecial,
  special = false,
  onChoose,
  loading,
}) => {
  const dollars = Math.trunc(price);
  const cents = _.padStart(Math.trunc((price - dollars) * 100).toString(10), 2, '0');
  return (
    <div className={special ? styles.panelSpecial : styles.panelNormal}>
      <div className={styles.speedWrapper}>
        <Text font="sofia" className={speedSpecial ? styles.speedSpecial : styles.speedNormal}>{speedMbps}</Text>
        <Text font="sofia" className={styles.speedUnit}>Mbps</Text>
      </div>
      <div className={styles.price}>
        <Text font="sofia" className={styles.dollarSign}>$</Text>
        <Text font="sofia" className={styles.dollars}>{dollars}</Text>
        <Text font="sofia" className={styles.cents}>{cents}</Text>
      </div>
      <div className={styles.paymentInfoWrapper}>
        <Text font="larsseit" className={styles.paymentInfo}>{paymentInfo}</Text>
        {paymentInfoSpecial && (
          <Text font="sofia" size="big" className={styles.paymentInfoSpecial}>{paymentInfoSpecial}</Text>
        )}
      </div>
      <Button
        variant="primary"
        onClick={onChoose}
        disabled={loading}
      >
        Choose Plan
      </Button>
    </div>
  );
};

export default PlanPanel;
