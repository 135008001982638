import React from 'react';
import {Switch, Route, HashRouter } from 'react-router-dom';
import styles from './styles/App.module.css';
import CheckAvailability from "./pages/CheckAvailability";
import AlreadyConnected from "./pages/AlreadyConnected";
import Available from './pages/Available';
import Unavailable from './pages/Unavailable';
import ComingSoon from './pages/ComingSoon';
import ThankYou from './pages/ThankYou';
import Connect1 from './pages/Connect1';
import Connect2 from './pages/Connect2';
import Connect3 from './pages/Connect3';
import ConnectThankYou from './pages/ConnectThankYou';
import InTouch from "./pages/InTouch";

export const paths = {
  home: '/',
  alreadyConnected: '/alreadyConnected',
  available: '/available',
  unavailable: '/unavailable',
  comingSoon: '/comingSoon',
  thankYou: '/thankYou',
  inTouch: '/inTouch',
  connect1: '/connect/1',
  connect2: '/connect/2',
  connect3: '/connect/3',
  connectThankYou: '/connect/thankYou',
};

const App: React.FC = () => {
  return (
    <div className={styles.app}>
      <HashRouter>
        <Switch>
          <Route exact path={paths.home}>
            <CheckAvailability />
          </Route>
          <Route path={paths.alreadyConnected}>
            <AlreadyConnected />
          </Route>
          <Route path={paths.available}>
            <Available />
          </Route>
          <Route path={paths.unavailable}>
            <Unavailable />
          </Route>
          <Route path={paths.comingSoon}>
            <ComingSoon />
          </Route>
          <Route path={paths.thankYou}>
            <ThankYou />
          </Route>
          <Route path={paths.inTouch}>
            <InTouch />
          </Route>
          <Route path={paths.connect1}>
            <Connect1 />
          </Route>
          <Route path={paths.connect2}>
            <Connect2 />
          </Route>
          <Route path={paths.connect3}>
            <Connect3 />
          </Route>
          <Route path={paths.connectThankYou}>
            <ConnectThankYou />
          </Route>
        </Switch>
      </HashRouter>
    </div>
  );
};

export default App;
