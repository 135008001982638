import React from 'react';
import styles from '../styles/pages/AlreadyConnected.module.css';
import SignupPage from './SignupPage';
import logo from '../assets/logo-transparent-bg.svg';
import Text from '../components/Text';

const AlreadyConnected: React.FC = () => {
  return (
    <SignupPage
      className={styles.alreadyConnected}
      title="Good News!"
      top={<img src={logo} alt="NetBlazr logo" aria-hidden={true}/>}
      centerVertically={true}
      isChatShown={false}
    >
      <Text font="larsseit" size="big" align="center" className={styles.content}>
        <p>Your building has netBlazr built-in and service is already live for residence.</p>
        <p>To get online, connect your router to the live ethernet jack in your apartment.</p>
        <p>Need some help? Check out our <a href="/">FAQ</a> or reach out to our team.</p>
        <Text font="larsseit" size="big" align="left" linksBlack linksUnderline="hover" className={styles.contacts}>
          <p className={styles.call}>617.718.5500 x3</p>
          <p className={styles.email}><a href="mailto:support@netblazr.com">support@netblazr.com</a></p>
        </Text>
      </Text>
    </SignupPage>
  );
};

export default AlreadyConnected;
