import React from 'react';
import styles from '../styles/components/Loading.module.css';
import { BeatLoader } from 'react-spinners';

export interface LoadingProps {
  size?: number;
  margin?: number;
  paddingVertical?: number;
  paddingHorizontal?: number;
}

const Loading: React.FC<LoadingProps> = ({
  size = 15,
  margin = 5,
  paddingVertical = 24,
  paddingHorizontal = 24,
}) => {
  return (
    <div
      style={{ padding: `${paddingVertical}px ${paddingHorizontal}px` }}
      className={styles.container}
    >
      <div className={styles.spinner}>
        <BeatLoader color="#B3B3B3" size={size} margin={margin} />
      </div>
    </div>
  );
};

export default Loading;
