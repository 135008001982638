/// <reference types="googlemaps" />
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from '../styles/pages/CheckAvailability.module.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../components/form/TextField';
import AddressField from '../components/form/AddressField';
import SignupPage from './SignupPage';
import Button from '../components/form/Button';
import Message from '../components/Message';
import { validate } from '../rest/address';
import { paths } from '../App';
import {AddressInfo, AddressValidationRequest, AddressValidationResponse} from '../model/address';
import { setAddressInfo, setAddressValidation, setGeocoderResult } from '../helpers/store';
import { getErrorMessage } from '../helpers/error';
import _ from 'lodash';
import {getGeocoderAddressComponent} from "../helpers/address";

interface AvailabilityFormValues {
  address: string;
}

const availabilityFormSchema = Yup.object<AvailabilityFormValues>().shape({
  address: Yup.string().trim().required('Address is required')
});

const CheckAvailability: React.FC = () => {
  const history = useHistory();
  const [error, setError] = useState<string | null>(null);
  const [address, setAddress] = useState<google.maps.GeocoderResult | null>(null);
  const availabilityFormInitialValues: AvailabilityFormValues = {
    address: '',
  };

  const geocoderResultToAddressInfo = (address: google.maps.GeocoderResult) => (
    {
      addressString: address.formatted_address,
      streetNumber: getGeocoderAddressComponent(['street_number'], address),
      street: getGeocoderAddressComponent(['route'], address),
      streetShort: getGeocoderAddressComponent(['route'], address, true),
      state: getGeocoderAddressComponent(['administrative_area_level_1'], address, true),
      city: getGeocoderAddressComponent(['locality'], address),
      zip: getGeocoderAddressComponent(['postal_code'], address),
      country: getGeocoderAddressComponent(['country'], address, true),
      latitude: address.geometry.location.lat(),
      longitude: address.geometry.location.lng(),
    } as AddressInfo
  )

  return (
    <SignupPage
      className={styles.checkAvailability}
      title="Check Availability"
      centerVertically={true}
      isChatShown={false}
    >
      <Formik
        initialValues={availabilityFormInitialValues}
        validationSchema={availabilityFormSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (address === null) {
            setSubmitting(false);
            setError('Please select an address from dropdown options to make sure it is correct');
            return;
          }
          try {
            setSubmitting(true);
            setError(null);
            const addressInfo = geocoderResultToAddressInfo(address)
            const request = {addressInfo: addressInfo} as AddressValidationRequest;
            const addressValidation: AddressValidationResponse = await validate(request);
            setSubmitting(false);
            setAddressValidation(addressValidation);
            setAddressInfo(addressInfo)
            setGeocoderResult(address);
            if (addressValidation.addressAvailability === 'Active') {
              if (addressValidation.buildingType === 'MDUBulk') {
                history.push(paths.alreadyConnected);
              } else if (addressValidation.buildingType === 'MDU') {
                history.push(paths.available);
              } else {
                history.push(paths.unavailable);
              }
            } else if (addressValidation.addressAvailability === 'UnderConstruction') {
              history.push(paths.comingSoon);
            } else if (addressValidation.addressAvailability === 'NotServiceable') {
              history.push(paths.unavailable);
            } else {
              console.error('Unexpected building status data: ' + JSON.stringify(addressValidation));
              setError('Building status check responded with unexpected status');
            }
          } catch (e) {
            setSubmitting(false);
            setError(getErrorMessage(e));
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.addressRow}>
              <AddressField
                name="address"
                placeholder="Address"
                onAddressSet={setAddress}
                onChange={() => setError(null)}
              />
            </div>
            {error && <Message size="small" type="error">{error}</Message>}
            <div className={styles.buttons}>
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                Next
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </SignupPage>
  );
};

export default CheckAvailability;
