/// <reference types="googlemaps" />
import {api} from './client';
import {AddressInfo, AddressValidationRequest, AddressValidationResponse} from '../model/address';
import { ServiceableAddress } from '../model/account';

export async function validate(request: AddressValidationRequest): Promise<AddressValidationResponse> {
  return api<AddressValidationResponse>('/address/validate', {
    method: 'POST',
    data: JSON.stringify(request),
  });
}

export async function getApartmentsForAddress(
  address: AddressInfo
): Promise<Array<ServiceableAddress>> {
  return api<Array<ServiceableAddress>>('/address/apartments', {
    method: 'POST',
    data: JSON.stringify(address),
  });
}
