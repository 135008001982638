import { useField, FieldAttributes } from 'formik';
import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import styles from '../../styles/components/form/AutosuggestField.module.css';
import textFieldStyles from '../../styles/components/form/TextField.module.css';

export interface AutosuggestFieldProps {
  items: Array<any>,
  label?: string,
  placeholder?: string,
  name: string, 
  getSuggestionValue: (s: any) => string,
  renderSuggestion: (s: any) => string
};

const AutosuggestField: React.FC<FieldAttributes<AutosuggestFieldProps>> = props => {

  const [field, meta, helpers] = useField(props);
  const [value, setValue] = useState<string>('');
  const { 
    items,
    label,
    placeholder,
    getSuggestionValue,
    renderSuggestion,
    validate,
    ...otherProps 
  } = props;
  const [suggestions, setSuggestions] = useState<Array<any>>(items);

  const withError = meta.touched && meta.error;

  const getSuggestionsForInput = (value: string, suggestions: Array<any>) => {
    const trimmedInput = value && value.trim();
    const l = trimmedInput ? trimmedInput.length : 0;
    
    const ss = l === 0 ? suggestions : suggestions.filter(s => {
      const v = getSuggestionValue(s);
      return v.slice(0, l) === trimmedInput
    });
    const res = ss.length === 0? suggestions : ss
    return res;
  };
  const requestSuggestionsFetch = ({ value }: {value: string}) => {
    setSuggestions(getSuggestionsForInput(value, items));
  };
  const shouldRenderSuggestions = (value: string, reason: string) => {
    return true
  };
  const clearSuggestions = () => {
    setSuggestions([]);
  };
  const suggestionSelected = (event: any, { suggestion, suggestionValue }: {suggestion: string, suggestionValue: string}) => {
    setValue(suggestionValue);
    helpers.setValue(suggestionValue);
  };
  const handleChange = (e: any, {newValue, method}: {newValue: string, method: string}) => { 
    setValue(newValue);
    field.onChange(e);
   };

  const inputProps = {
    ...otherProps,
    placeholder: placeholder || '',
    value,
    onChange: handleChange,
    className: [
      withError ? textFieldStyles.inputError : textFieldStyles.inputNormal,
      props.disabled? textFieldStyles.grey : '',
    ].join(' ')
  }

  return (
    <div className = {textFieldStyles.field}>
      <label className={textFieldStyles.label} htmlFor={field.name} >
        { label }
      </label>
      
      <Autosuggest 
        suggestions={suggestions}
        onSuggestionsFetchRequested={requestSuggestionsFetch}
        onSuggestionsClearRequested={clearSuggestions}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        shouldRenderSuggestions={shouldRenderSuggestions}
        inputProps={inputProps as any}
        onSuggestionSelected={suggestionSelected}
        highlightFirstSuggestion={true}
        theme={styles}
      />
      <div className={textFieldStyles.error}>{withError ? meta.error : ''}&nbsp;</div>
    </div>
  )
}

export default AutosuggestField;