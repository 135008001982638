import React from 'react';

const useMediaQuery = (queryInput: string) => {
  const query = queryInput.replace(/^@media( ?)/m, '');
  const supportMatchMedia = window && typeof window.matchMedia;

  const [match, setMatch] = React.useState(() => {
    return supportMatchMedia ? window.matchMedia(query).matches : undefined;
  });

  React.useEffect(() => {
    if (!supportMatchMedia) {
      return undefined;
    }
    const queryList = window.matchMedia(query);
    const updateMatch = () => {
      setMatch(queryList.matches);
    };
    updateMatch();
    queryList.addListener(updateMatch);
    return () => {
      queryList.removeListener(updateMatch);
    };
  }, [query, supportMatchMedia]);

  return match;
};

export default useMediaQuery;
