import React, { useEffect, useRef, useState } from 'react';
import { useField, FieldAttributes } from 'formik';
import textFieldStyles from '../../styles/components/form/TextField.module.css';
import styles from '../../styles/components/form/DropdownField.module.css';
import _ from 'lodash';
import { TextFieldProps } from './TextField';

export interface DropdownOption {
  value: string;
  text: string;
}

export interface DropdownFieldProps extends TextFieldProps {
  options: DropdownOption[];
}

function getOptionText(options: DropdownOption[], value: string): string {
  const option = options.find(o => o.value === value);
  return option ? option.text : '';
}

const DropdownField: React.FC<FieldAttributes<DropdownFieldProps>> = props => {
  const [value, setValue] = useState('');
  const [field, meta, helpers] = useField(props);
  const {
    label,
    placeholder,
    options,
    ...inputProps
  } = props;
  const withError = meta.touched && meta.error;

  const [isVisible, setIsVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const hideDropdownMenu = useRef<() => void>(() => {});

  useEffect(() => {
    let ignore = false; // to prevent accessing state of an unmounted component

    hideDropdownMenu.current = () => {
      if (!ignore && isVisible) {
        setIsVisible(false);
      }
      document.removeEventListener('click', hideDropdownMenu.current);
    };

    document.addEventListener('click', hideDropdownMenu.current);

    return () => {
      ignore = true;
      document.removeEventListener('click', hideDropdownMenu.current);
    };
  }, [isVisible]);

  const showDropdownMenu = (event: React.MouseEvent) => {
    if (!isVisible) {
      event.preventDefault();
      setIsVisible(true);
      document.addEventListener('click', hideDropdownMenu.current);
    }
  };

  return (
    <div className={[textFieldStyles.field, styles.optionsContainer].join(' ')}>
      <label className={textFieldStyles.label} htmlFor={field.name}>
        {label || (!_.isEmpty(value) ? placeholder : '')}&nbsp;
      </label>
      <input
        type="hidden"
        {...field}
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        {...(inputProps as any)}
      />
      <div
        className={[
          withError ? textFieldStyles.inputError : textFieldStyles.inputNormal,
          _.isEmpty(getOptionText(options, value)) ? textFieldStyles.inputWithPlaceholder : '',
        ].join(' ')}
        onClick={showDropdownMenu}
        tabIndex={0}
        role="combobox"
        aria-autocomplete="list"
        aria-expanded={isVisible}
        aria-controls={props.name + 'listbox'}
        aria-owns={props.name + 'listbox'}
        onKeyPressCapture={e => {
          if (e.key.toLowerCase() === 'enter') {
            if (isVisible) {
              setValue(options[selectedIndex].value);
              helpers.setValue(options[selectedIndex].value);
            }
            setIsVisible(!isVisible);
          }
        }}
        onKeyDownCapture={e => {
          if (isVisible) {
            if (e.keyCode === 38) {
              const newIndex = selectedIndex - 1 < 0
                ? options.length - 1
                : selectedIndex - 1;
              setSelectedIndex(newIndex);
            } else if (e.keyCode === 40) {
              const newIndex = selectedIndex + 1 >= options.length
                ? 0
                : selectedIndex + 1;
              setSelectedIndex(newIndex);
            }
          }
        }}
      >
        {getOptionText(options, value) || placeholder || ''}&nbsp;
      </div>
      {isVisible && (options.length > 0) && (
        <ul className={styles.options} role="listbox" id={props.name + 'listbox'}>
          {options.map((option, idx) => (
            <li
              key={option.value}
              role="option"
              aria-selected={idx === selectedIndex}
              className={[styles.option, idx === selectedIndex ? styles.selected : ''].join(' ')}
              onClick={() => {
                setValue(option.value);
                helpers.setValue(option.value);
              }}
            >
              {option.text}
            </li>
          ))}
        </ul>
      )}
      <div className={textFieldStyles.error}>{withError ? meta.error : ''}&nbsp;</div>
    </div>
  );
};

export default DropdownField;
