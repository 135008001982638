/// <reference types="googlemaps" />
import _ from 'lodash';

export function getGeocoderAddressComponent(
  types: string[],
  address: google.maps.GeocoderResult,
  short: boolean = false,
): string {
  const component = _.find(
    address.address_components,
    c => _.intersection(c.types, types).length === types.length,
  );
  return component
    ? (short ? component.short_name : component.long_name)
    : '';
}
