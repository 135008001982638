import React, { useState } from 'react';
import styles from '../styles/pages/Connect.module.css';
import StepperPage from './StepperPage';
import Text from '../components/Text';
import Button from '../components/form/Button';
import { useHistory } from 'react-router-dom';
import { paths } from '../App';
import Message from '../components/Message';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import Heading from '../components/Heading';
import { getErrorMessage } from '../helpers/error';
import TextField from '../components/form/TextField';
import CheckboxField from '../components/form/CheckboxField';
import {getAccountDetails, getGeocoderResult, getSchedule, getServicePlan, getSonarAccountInfo} from '../helpers/store';
import { toUSTime } from '../components/ScheduleWindowPicker';
import { addMonths } from 'date-fns';
import { getGeocoderAddressComponent } from '../helpers/address';
import useMediaQuery from '../helpers/useMediaQuery';
import {setBillingInfo} from "../rest/account";
import {BillingRequest} from "../model/account";

interface BillingFormValues {
  nameOnCard: string;
  cardNumber: string;
  expirationDate: string;
  cvc: string;

  sameAddress: boolean;
  billingStreet: string;
  apartment: string;
  city: string;
  state: string;
  zip: string;

  promoCode: string;

  privacyPolicy: boolean;
  conciergeAnnualNoRefund?: boolean;
}

function getBillingFormSchema(isConciergeAnnual: boolean) {
  return Yup.object<BillingFormValues>().shape({
    nameOnCard: Yup.string().trim()
      .matches(/^\w+\s+\w+$/i, 'Invalid name')
      .required('Name is required'),
    cardNumber: Yup.string().trim()
      .matches(/^\d{4}\s*\d{4}\s*\d{4}\s*\d{4}$/i, 'Invalid card number')
      .required('Card number is required'),
    expirationDate: Yup.string().trim()
      .matches(/^\d\d\s*\/\s*\d\d(\d\d)?$/i, 'Invalid expiration date')
      .required('Expiration date is required'),
    cvc: Yup.string().trim()
      .matches(/^\d{3,4}$/i, 'Invalid CVC')
      .required('CVC is required'),

    sameAddress: Yup.boolean(),
    billingStreet: Yup.string().trim().required('Address is required'),
    apartment: Yup.string().trim().nullable(true),
    city: Yup.string().trim().required('City is required'),
    state: Yup.string().trim()
      .matches(/[A-Z]{2}/i, 'Invalid state')
      .required('State is required'),
    zip: Yup.string().trim()
      .matches(/^\d{5}(-\d{4})?$/i, 'Invalid zip code')
      .required('Zip code is required'),

    promoCode: Yup.string().trim(),

    privacyPolicy: Yup.boolean()
      .oneOf([true], 'You need to accept privacy policy')
      .required('You need to accept privacy policy'),
    conciergeAnnualNoRefund: isConciergeAnnual
      ? Yup.boolean()
        .oneOf([true], 'You need to accept this condition')
        .required('You need to accept this condition')
      : Yup.boolean(),
  });
}

function toUSDate(date: Date): string {
  return _.padStart((date.getMonth() + 1).toString(), 2, '0')
    + '/' + _.padStart(date.getDate().toString(), 2, '0')
    + '/' + date.getFullYear().toString();
}

const PlanInfoRow: React.FC<{ title: string, value: string }> = ({ title, value }) => {
  return (
    <div>
      <Text font="larsseit">
        {title}
      </Text>
      <Text font="larsseit" size="small">
        {value}
      </Text>
    </div>
  );
};

const Connect3: React.FC = () => {
  const history = useHistory();
  const [error, setError] = useState<JSX.Element>();
  const [sameAddress, setSameAddress] = useState(false);
  const min500 = useMediaQuery("(min-width: 500px)");

  const servicePlan = getServicePlan();
  if (!servicePlan) {
    history.push(paths.available);
    return null;
  }
  const planType = /concierge/gi.test(servicePlan.internalCode)
    ? 'Concierge'
    : (/standard/gi.test(servicePlan.internalCode) ? 'Standard' : servicePlan.visibleName);
  const isConciergeAnnual = planType === 'Concierge' && servicePlan.billingPeriod === 'Annual';

  const schedule = getSchedule();
  if (!schedule) {
    history.push(paths.connect2);
    return null;
  }

  const sonarAccountInfo = getSonarAccountInfo()
  if (!sonarAccountInfo) {
    history.push(paths.home);
    return null;
  }

  const appointmentDate = new Date(schedule.year, schedule.month - 1, schedule.dayOfMonth);
  const billingDate = addMonths(appointmentDate, 1);

  const initialValues: BillingFormValues = {
    nameOnCard: '',
    cardNumber: '',
    expirationDate: '',
    cvc: '',

    sameAddress: false,
    billingStreet: '',
    apartment: '',
    city: '',
    state: '',
    zip: '',

    promoCode: '',

    privacyPolicy: false,
    conciergeAnnualNoRefund: false,
  };

  return (
    <StepperPage
      step={3}
      className={styles.connect}
      title="Enter your billing details"
      isChatShown={true}
    >
      <Text font="larsseit" size="big" align="center">
        <p>You’re just a few steps away from having Boston’s best Internet in your home.</p>
      </Text>
      <Formik
        initialValues={initialValues}
        validationSchema={getBillingFormSchema(isConciergeAnnual)}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const nameOnCard = _.trim(values.nameOnCard.replace(/\s{2,}/g, ' '));
            const cardNumber = _.trim(values.cardNumber.replace(/\s/g, ''));
            const expiration = values.expirationDate.split("/").map(v => _.trim(v));
            const year = Number.parseInt(expiration[1], 10);
            const expirationMonth = Number.parseInt(expiration[0], 10);
            const cvc = _.trim(values.cvc);
            const billing: BillingRequest = {
              accountId: sonarAccountInfo.accountId,
              cardInfo: {
                nameOnCard,
                cardNumber,
                expirationMonth,
                expirationYear: year > 100 ? year : year + 2000,
                cvc,
              },
              billingAddress: {
                billingStreet: _.trim(values.billingStreet),
                city: _.trim(values.city),
                state: _.trim(values.state),
                zip: _.trim(values.zip),
                apartment: values.apartment && !_.isEmpty(_.trim(values.apartment)) ? _.trim(values.apartment) : undefined,
              },
              promoCode: values.promoCode && !_.isEmpty(_.trim(values.promoCode)) ? _.trim(values.promoCode) : undefined,
            } as BillingRequest;
            setSubmitting(true);
            setError(undefined);
            await setBillingInfo(billing);
            setSubmitting(false);
            history.push(paths.connectThankYou);
          } catch (e) {
            setSubmitting(false);
            setError(getErrorMessage(e));
          }
        }}
      >
        {({ handleSubmit, isSubmitting, errors, touched, values, setFieldValue }) => (
          <form onSubmit={handleSubmit} className={styles.billingForm}>
            <div className={[styles.twoColumns, styles.fillHeight].join(' ')}>
              <div>
                <Heading component="h3" size="small" className={styles.formTitle}>
                  Card Info
                </Heading>
                <TextField
                  name="nameOnCard"
                  label="Name on Card:"
                  placeholder="JOHN DOE"
                />
                <TextField
                  name="cardNumber"
                  label="Card Number:"
                  placeholder="XXXX XXXX XXXX XXXX"
                />
                <div className={styles.formRow}>
                  <TextField
                    name="expirationDate"
                    label="Expiration Date:"
                    placeholder="MM/YY"
                  />
                  <TextField
                    name="cvc"
                    label="CVC:"
                    placeholder="Digits on the back"
                  />
                </div>
                <Heading component="h3" size="small" className={styles.formTitle}>
                  Billing Address
                </Heading>
                <CheckboxField
                  name="sameAddress"
                  label="Billing Address is the same as Service Address."
                  labelSize="small"
                  onCheckedChange={(checked: boolean) => {
                    if (checked) {
                      setSameAddress(true);
                      const accountDetails = getAccountDetails();
                      if (accountDetails) {
                        setFieldValue('apartment', accountDetails.apartment.unitNumber);
                      }
                      const address = getGeocoderResult();
                      if (address) {
                        const streetNumber = getGeocoderAddressComponent(['street_number'], address);
                        const route = getGeocoderAddressComponent(['route'], address);
                        setFieldValue(
                          'billingStreet',
                          `${streetNumber} ${route}`,
                        );
                        setFieldValue(
                          'state',
                          getGeocoderAddressComponent(['administrative_area_level_1'], address, true),
                        );
                        setFieldValue(
                          'city',
                          getGeocoderAddressComponent(['locality'], address),
                        );
                        setFieldValue(
                          'zip',
                          getGeocoderAddressComponent(['postal_code'], address),
                        );
                      }
                    } else {
                      setSameAddress(false);
                    }
                  }}
                />
                <TextField
                  name="billingStreet"
                  label="Billing Street:"
                  disabled={sameAddress && !_.isEmpty(values.billingStreet)}
                />
                <div className={styles.formRow}>
                  <TextField
                    name="apartment"
                    label="Apartment #:"
                    disabled={sameAddress && !_.isEmpty(values.apartment)}
                  />
                  <TextField
                    name="city"
                    label="City/Town:"
                    disabled={sameAddress && !_.isEmpty(values.city)}
                  />
                </div>
                <div className={styles.formRow}>
                  <TextField
                    name="state"
                    label="State:"
                    placeholder="2 letters, e.g. 'MA'"
                    disabled={sameAddress && !_.isEmpty(values.state)}
                  />
                  <TextField
                    name="zip"
                    label="Zip Code:"
                    placeholder="XXXXX"
                    disabled={sameAddress && !_.isEmpty(values.zip)}
                  />
                </div>
              </div>
              <div>
                <div className={styles.planInfo}>
                  <div className={styles.blockHeading}>
                    <Heading component="h4" bold size="small" separator="green">
                      Your Internet Plan
                    </Heading>
                    <div className={styles.planData}>
                      <PlanInfoRow
                        title={planType + '/' + servicePlan.billingPeriod}
                        value={'$' + servicePlan.price + '/' + (servicePlan.billingPeriod === 'Annual' ? 'yr' : 'mo')}
                      />
                      <PlanInfoRow
                        title="Install Date"
                        value={toUSDate(appointmentDate)}
                      />
                      <PlanInfoRow
                        title="Appointment Window"
                        value={toUSTime(schedule.startHour) + ' - ' + toUSTime(schedule.endHour)}
                      />
                      <PlanInfoRow
                        title="Billing Start Date"
                        value={toUSDate(billingDate)}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.promoCode}>
                  {min500 && <Text font="larsseit" size="big">Promo code:</Text>}
                  <TextField
                    name="promoCode"
                    label={min500 ? '' : 'Promo code:'}
                  />
                </div>
                <div className={styles.routerInfo}>
                  <div className={styles.blockHeading}>
                    <Heading component="h4" bold size="small">
                      Need a Router?
                    </Heading>
                    <Text
                      align="center"
                      linksBlack
                      linksUnderline="always"
                      className={styles.routerText}
                    >
                      netBlazr doesn’t provide a wireless router,
                      but we’ll be happy to help you choose one
                      and set it up. For more info, visit our{' '}
                      <a
                        href="https://www.netblazr.com/docs-category/choosing-a-router/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Recommendations Page
                      </a>.
                    </Text>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.checkboxes}>
              <Heading component="h3" size="small" className={styles.serviceAgreementTitle}>
                Service Agreement
              </Heading>
              <CheckboxField
                name="privacyPolicy"
                label={
                  <>
                    I agree to the Terms of Service and Privacy policy, as{' '}
                    <a
                      href="https://www.netblazr.com/policies/#privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      listed on the netBlazr website
                    </a>.
                  </>
                }
              />
              {isConciergeAnnual && (
                <CheckboxField
                  name="conciergeAnnualNoRefund"
                  label="Please check this box to acknowledge our Concierge Annual Plan is non-refundable after the first 30 days of service."
                />
              )}
            </div>
            {error && <Message type="error">{error}</Message>}
            {(
              _.keys(errors)
                .filter(k => touched[k as keyof BillingFormValues])
                .filter(k => !_.isEmpty(errors[k as keyof BillingFormValues]))
                .length > 0
            ) && (
              <Message type="error">Form has errors</Message>
            )}
            <div className={styles.buttons}>
              <Button
                variant="normal"
                onClick={() => history.push(paths.connect2)}
              >
                Back
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={isSubmitting}
              >
                Next
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </StepperPage>
  );
};

export default Connect3;
