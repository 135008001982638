export interface AppConfig {
  type: 'local' | 'dev' | 'staging' | 'demo' | 'prod';
  baseUrl: string;
  chatUrl: string;
}

const localConfig: AppConfig = {
  type: 'local',
  baseUrl: 'http://localhost:9010/api/v1.0',
  chatUrl: 'https://code.tidio.co/izi0ldhfzrahuoef4de3s1fuy3ooehuh.js'
};

const devConfig: AppConfig = {
  type: 'dev',
  baseUrl: 'https://svc.nbwebform.swiftinvention.com/api/v1.0',
  chatUrl: 'https://code.tidio.co/6p9wcrpwxellpa5vcj98p1wfvmfyj2ij.js'
};

const prodConfig: AppConfig = {
  type: 'prod',
  baseUrl: '',
  chatUrl: 'https://code.tidio.co/6p9wcrpwxellpa5vcj98p1wfvmfyj2ij.js'
}

// TODO: Add other environments' configurations

const getConfig = (): AppConfig => {
  switch (process.env.REACT_APP_STAGE) {
    case 'local':
      return localConfig;
    case 'dev':
      return devConfig;
    case 'staging':
      return localConfig;
    case 'demo':
      return localConfig;
    case 'prod':
      return localConfig;
    default:
      throw new Error('Unknown REACT_APP_STAGE env was used in app configuration');
  }
};

const config = getConfig();

export default config;
