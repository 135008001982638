import React from 'react';
import styles from '../../styles/components/form/Button.module.css';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'normal' | 'primary';
}

const Button: React.FC<ButtonProps> = ({
  variant = 'normal',
  className,
  children,
  ...props
}) => {
  return (
    <button
      {...props}
      className={[
        className,
        variant === 'primary' ? styles.btnPrimary : styles.btnNormal,
      ].join(' ')}
    >
      {children}
    </button>
  );
};

export default Button;
