import React, { useEffect } from 'react';
import styles from '../styles/pages/SignupPage.module.css';
import Heading from '../components/Heading';
import config from '../config';

export interface SignupPageProps extends React.HTMLProps<HTMLDivElement> {
  top?: JSX.Element;
  title: string;
  titleSeparator?: 'green' | 'orange';
  centerVertically?: boolean;
  isChatShown: boolean
}

const SignupPage: React.FC<SignupPageProps> = ({
  top,
  title,
  titleSeparator = 'green',
  children,
  centerVertically = false,
  className,
  isChatShown
}) => {

  useEffect(() => {
    const nodeId = 'script'
    const script = document.createElement(nodeId);
    if (isChatShown) {    
      script.src = config.chatUrl;
      script.async = true;
      document.body.appendChild(script);
    }
    return () => {
      const node = document.getElementById(nodeId);
      node && document.body.removeChild(node);      
    }
  }, [isChatShown]);

  return (
    <div className={[
      centerVertically ? styles.pageAlignCenter : styles.pageAlignTop,
      className,
    ].join(' ')}>
      <header className={styles.header}>
        {top && <div className={styles.top}>{top}</div>}
        <Heading component="h2" size="big" bold separator={titleSeparator}>{title}</Heading>
      </header>
      <article className={styles.content}>
        {children}
      </article>
    </div>
  );
};

export default SignupPage;
