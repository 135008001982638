import React from 'react';
import styles from '../styles/components/Message.module.css';
import Text, { TextProps } from './Text';

export interface MessageProps extends TextProps {
  type: 'info' | 'success' | 'error';
}

const Message: React.FC<MessageProps> = ({
  type,
  size = 'normal',
  className,
  children,
  ...props
}) => {
  return (
    <Text
      className={[styles[type], className].join(' ')}
      size={size}
      {...props}
    >
      {children}
    </Text>
  );
};

export default Message;
