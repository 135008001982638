import React from 'react';
import styles from '../styles/pages/Unavailable.module.css';
import SignupPage from './SignupPage';
import Text from '../components/Text';
import {WaitListForm} from '../components/form/WaitListForm';
import {getAddressInfo} from '../helpers/store';
import {useHistory} from 'react-router-dom';
import {paths} from '../App';
import {AccountStatus} from "../model/AccountStatus";

const Unavailable: React.FC = () => {
  const history = useHistory();
  const addressInfo = getAddressInfo();
  return (
    <SignupPage
      className={styles.unavailable}
      title="Service is Unavailable :("
      titleSeparator="orange"
      centerVertically={true}
      isChatShown={false}
    >
      <Text font="larsseit" size="big" align="center" className={styles.content}>
        <p>
          We're always bringing service to new buildings.<br />
          Join our waitlist and we’ll notify you when service is available.
        </p>
      </Text>
      <WaitListForm
        addressInfo={addressInfo}
        apartment={''}
        accountStatus={AccountStatus.LeadOutOfCoverage}
        onNext={() => {
          history.push(paths.inTouch);
        }}
      />
    </SignupPage>
  );
};

export default Unavailable;
