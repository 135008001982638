import React from 'react';
import _ from 'lodash';
import styles from '../styles/components/Heading.module.css';

export interface HeadingProps extends Omit<React.HTMLProps<HTMLHeadingElement>, 'size'> {
  component: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  size: 'big' | 'medium' | 'small' | 'smaller';
  bold?: boolean;
  uppercase?: boolean;
  separator?: boolean | 'green' | 'orange';
  marginTop?: 0 | 1 | 2;
}

const Heading: React.FC<HeadingProps> = ({
  component,
  size,
  bold = false,
  uppercase = false,
  separator = false,
  marginTop = 1,
  children,
  className,
}) => {
  const Elem = component;
  return (
    <>
      <Elem
        className={[
          bold ? styles.headingBold : styles.headingRegular,
          styles['size' + _.capitalize(size)],
          uppercase ? styles.uppercase : '',
          styles['mt' + marginTop],
          className,
        ].join(' ')}
      >
        {children}
      </Elem>
      {separator && (
        <div className={separator === 'orange' ? styles.separatorOrange : styles.separatorGreen} />
      )}
    </>
  );
};

export default Heading;
