/// <reference types="googlemaps" />
import React, { useState } from 'react';
import { useField, FieldAttributes } from 'formik';
import textFieldStyles from '../../styles/components/form/TextField.module.css';
import styles from '../../styles/components/form/AddressField.module.css';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';
import _ from 'lodash';
import { TextFieldProps } from './TextField';

export interface AddressFieldProps extends TextFieldProps {
  onAddressSet: (address: google.maps.GeocoderResult | null) => void;
}

// const sessionToken = new google.maps.places.AutocompleteSessionToken();

const AddressField: React.FC<FieldAttributes<AddressFieldProps>> = props => {
  const [value, setValue] = useState('');
  const [field, meta, helpers] = useField(props);
  const {
    label,
    placeholder,
    onAddressSet,
    onBlur,
    onChange,
    ...inputProps
  } = props;
  const withError = meta.touched && meta.error;

  return (
    <PlacesAutocomplete
      value={value}
      onChange={value => {
        setValue(value);
        onAddressSet(null); // Reset when editing
      }}
      onSelect={(address, placeID) => {
        setValue(address);
        geocodeByPlaceId(placeID)
          .then(results => onAddressSet(results.length > 0 ? results[0] : null))
      }}
      onError={(status, clearSuggestions) => {
        console.error('Google Maps API returned error with status: ', status);
        clearSuggestions();
      }}
      debounce={500}
      searchOptions={{
        types: ['address'],
        // sessionToken,
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        const autocompleteInputProps = getInputProps();
        const onBlurMixed = (e: React.FocusEvent<HTMLInputElement>) => {
          autocompleteInputProps.onBlur(e);
          helpers.setValue(e.target.value);
          onBlur && onBlur(e as any);
        };
        const onChangeMixed = (e: React.ChangeEvent<HTMLInputElement>) => {
          autocompleteInputProps.onChange(e);
          helpers.setValue(e.target.value);
          onChange && onChange(e as any);
        };
        return (
          <div className={[textFieldStyles.field, styles.suggestionsContainer].join(' ')}>
            <label className={textFieldStyles.label} htmlFor={field.name}>
              {label || (!_.isEmpty(value) ? placeholder : '')}&nbsp;
            </label>
            <input
              className={withError ? textFieldStyles.inputError : textFieldStyles.inputNormal}
              placeholder={placeholder || ''}
              {...field}
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              {...(inputProps as any)}
              {...autocompleteInputProps}
              onBlur={onBlurMixed}
              onChange={onChangeMixed}
            />
            <ul className={styles.suggestions}>
              {loading && <li className={styles.suggestionLoading}>Loading...</li>}
              {suggestions.map(suggestion => {
                const itemProps = getSuggestionItemProps(suggestion);
                return (
                  <li
                    {...itemProps}
                    className={suggestion.active ? styles.suggestionActive : styles.suggestionNormal}
                    onClick={event => itemProps.onClick(event)}
                  >
                    {suggestion.description}
                  </li>
                );
              })}
            </ul>
            <div className={textFieldStyles.error}>{withError ? meta.error : ''}&nbsp;</div>
          </div>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default AddressField;
