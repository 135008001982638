import React, {useState} from 'react';
import styles from '../styles/pages/Available.module.css';
import SignupPage from './SignupPage';
import Text from '../components/Text';
import Heading from '../components/Heading';
import PlanPanel from '../components/PlanPanel';
import {getAddressValidation, setServicePlan} from '../helpers/store';
import Message from '../components/Message';
import {ServicePlan} from '../model/address';
import {useHistory} from 'react-router-dom';
import {paths} from '../App';

const Available: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const setPlan = (servicePlan: ServicePlan) => {
    setServicePlan(servicePlan);
    history.push(paths.connect1);
  };

  const addressValidation = getAddressValidation();
  const residentialPlans = addressValidation
    ? addressValidation.residentialPlans.filter(plan => plan.serviceType === 'Residential')
    : [];
  const conciergePlans = residentialPlans.filter(plan => /Concierge/i.test(plan.internalCode));
  const standardPlans = residentialPlans.filter(plan => /Standard/i.test(plan.internalCode));

  return (
    <SignupPage
      className={styles.available}
      title="Good News!"
      isChatShown={true}
    >
      <Text font="larsseit" size="big" align="center" className={styles.content}>
        <p>Our Service is available in your building.</p>
      </Text>
      <div className={styles.advantages}>
        <div className={styles.noContract}>
          <Text font="larsseit" align="center">No<br/>Contract</Text>
        </div>
        <div className={styles.noRateHikes}>
          <Text font="larsseit" align="center">No<br/>Rate Hikes</Text>
        </div>
        <div className={styles.noHiddenFees}>
          <Text font="larsseit" align="center">No<br/>Hidden Fees</Text>
        </div>
        <div className={styles.freeInstallation}>
          <Text font="larsseit" align="center">Free<br/>Installation</Text>
        </div>
      </div>
      <Text align="center" className={styles.promoCodeNote}>
        <em>Have a promotional code? Enter it with your billing information.</em>
      </Text>
      {error && <Message size="small" type="error">{error}</Message>}
      {residentialPlans.length === 0 && (
        <Message size="small" type="error">No plans available for this building</Message>
      )}
      {
        conciergePlans.length > 0 && (
          <>
            <div className={styles.headingWrapper}>
              <Heading component="h3" size="medium" marginTop={0} bold uppercase>
                <span className={styles.outlineFont}>Concierge</span> Internet
              </Heading>
            </div>
            <div className={styles.panels}>
              {conciergePlans.map(plan => (
                <PlanPanel
                  key={plan.internalCode}
                  speedSpecial
                  speedMbps={plan.downloadSpeed}
                  price={plan.billingPeriod === 'Annual' ? plan.price / 12 : plan.price}
                  paymentInfo={plan.billingPeriod === 'Annual' ? `$${plan.price} Annually` : 'Month-to-Month'}
                  onChoose={() => setPlan(plan)}
                  loading={loading}
                />
              ))}
            </div>
          </>
        )
      }
      {
        standardPlans.length > 0 && (
          <>
            <div className={styles.headingWrapper}>
              <Heading component="h3" size="medium" marginTop={0} bold uppercase>
                Standard Internet
              </Heading>
              {/*<Text className={styles.badge} font="sofia" size="big">One Month Free</Text>*/}
            </div>
            <div className={styles.panels}>
              {standardPlans.map(plan => (
                <PlanPanel
                  key={plan.internalCode}
                  speedMbps={plan.downloadSpeed}
                  price={plan.billingPeriod === 'Annual' ? plan.price / 12 : plan.price}
                  paymentInfo={plan.billingPeriod === 'Annual' ? `$${plan.price} Annually` : 'Month-to-Month'}
                  onChoose={() => setPlan(plan)}
                  loading={loading}
                />
              ))}
            </div>
          </>
        )
      }
    </SignupPage>
  );
};

export default Available;
