import React, {useState} from 'react';
import styles from '../../styles/components/form/ContactInfoForm.module.css';
import {Formik} from 'formik';
import * as Yup from 'yup';
import Button from './Button';
import TextField from './TextField';
import DropdownField from './DropdownField';
import {saveWaitListContactInfo} from '../../rest/account';
import {AccountInfo, AccountWaitListContactInfo} from '../../model/account';
import {getErrorMessage} from '../../helpers/error';
import Message from '../Message';
import {
  addressSchema,
  ContactInfoFormValues,
  emailAddressSchema,
  firstNameSchema,
  foundFromOptions,
  foundFromSchema,
  lastNameSchema,
} from './ContactInfoForm';
import {AccountStatus} from "../../model/AccountStatus";
import {AddressInfo} from "../../model/address";

type WaitListFormValues = Omit<ContactInfoFormValues, 'phoneNumber'>;


const waitListFormSchema = Yup.object<WaitListFormValues>().shape({
  address: addressSchema,
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  emailAddress: emailAddressSchema,
  foundFrom: foundFromSchema,
});

export interface WaitListFormProps{
  addressInfo: AddressInfo | null;
  apartment?: string;
  accountStatus: AccountStatus;
  onNext: () => void;
}

export const WaitListForm: React.FC<WaitListFormProps> = ({
  addressInfo,
  accountStatus,
  onNext,
}) => {
  const [error, setError] = useState<string | null>(null);
  const initialValues: WaitListFormValues = {
    address: addressInfo?.addressString || '',
    apartment: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    foundFrom: '',
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={waitListFormSchema}
      onSubmit={async (values, { setSubmitting }) => {
        if (!addressInfo) return;
        setSubmitting(true);
        try {
          const accountDetails: AccountWaitListContactInfo = {
            ...values,
            addressInfo: addressInfo,
            accountInfo: { ...values} as AccountInfo,
            accountStatus: accountStatus
          };
          await saveWaitListContactInfo(accountDetails);
          setSubmitting(false);
          onNext();
        } catch (e) {
          setError(getErrorMessage(e));
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className={styles.contactInfoForm}>
          <div className={styles.addressRow}>
            <TextField
              name="address"
              label="Your Address"
              disabled
              bold
            />
            <TextField name="apartment" label="Apartment #"/>
          </div>
          <div className={styles.equalRow}>
            <TextField name="firstName" label="First Name"/>
            <TextField name="lastName" label="Last Name"/>
          </div>
          <TextField name="emailAddress" label="Email" type="email"/>
          <DropdownField
            name="foundFrom"
            label="How did you find out about netBlazr?"
            placeholder="Please select"
            options={foundFromOptions}
          />
          {error && <Message size="small" type="error">{error}</Message>}
          <div className={styles.buttons}>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              Next
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
