import React, { useEffect, useState } from 'react';
import styles from '../styles/pages/Connect.module.css';
import StepperPage from './StepperPage';
import Text from '../components/Text';
import Calendar from '../components/Calendar';
import Button from '../components/form/Button';
import { Link, useHistory } from 'react-router-dom';
import { paths } from '../App';
import {getAddressValidation, getSonarAccountInfo, setSchedule} from '../helpers/store';
import {OneDayTimeSlot, AppointmentWindow, ScheduleJobRequest} from '../model/schedule';
import Message from '../components/Message';
import ScheduleWindowPicker from '../components/ScheduleWindowPicker';
import { getErrorMessage } from '../helpers/error';
import {scheduleJob} from "../rest/schedule";

const Connect2: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<JSX.Element>();
  const [day, setDay] = useState<OneDayTimeSlot>();
  const [window, setWindow] = useState<AppointmentWindow>();

  const addressValidation = getAddressValidation();
  const addressId = addressValidation ? addressValidation.addressId : null;
  const jobId = getSonarAccountInfo()?.jobId ?? -1;

  const sonarAccountInfo = getSonarAccountInfo();
  if (!sonarAccountInfo) {
    history.push(paths.connect1);
  }

  useEffect(() => {
    if (!addressId) {
      setError(
        <>
          You have not selected a valid address.
          Probably you got to this page before selecting an address.
          Please return to the <Link to={paths.home}>address selection step</Link>
        </>,
      );
    }
  }, [addressId]);

  return !sonarAccountInfo ? null : (
    <StepperPage
      step={2}
      className={styles.connect}
      title="Schedule an Installation"
      isChatShown={true}
    >
      <Text font="larsseit" size="big" align="center">
        <p>Installation is free, but you’ll need to be present in your unit during the appointment</p>
      </Text>
      {error && <Message type="error">{error}</Message>}
      <div className={styles.twoColumns}>
        <Calendar addressId={addressId || 0} jobId={jobId} onSelect={setDay} onError={setError}/>
        <ScheduleWindowPicker day={day} onSelect={setWindow}/>
      </div>
      <Text align="center" className={styles.bottomNote}>
        <em>
          Please note, our appointments are arrival windows
          to accommodate for traffic and other unforeseen issues.
        </em>
      </Text>
      <div className={styles.buttons}>
        <Button
          variant="normal"
          onClick={() => history.push(paths.connect1)}
        >
          Back
        </Button>
        <Button
          variant="primary"
          onClick={async () => {
            if (!addressId) {
              return;
            }
            if (!day) {
              setError(<>You have not selected a date</>);
              return;
            }
            if (!window) {
              setError(<>You have not selected a time window</>);
              return;
            }
            try {
              setLoading(true);
              const date = new Date(day.date);
              const request: ScheduleJobRequest = {
                accountId: sonarAccountInfo.accountId,
                jobId: sonarAccountInfo.jobId,
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                dayOfMonth: date.getDate(),
                startHour: window.start,
                endHour: window.end,
              };
              await scheduleJob(request);
              setSchedule(request);
              setLoading(false);
              history.push(paths.connect3);
            } catch (e) {
              setLoading(false);
              setError(getErrorMessage(e));
            }
          }}
          disabled={!addressId || !day || !window || loading}
        >
          Next
        </Button>
      </div>
    </StepperPage>
  );
};

export default Connect2;
