import React from 'react';
import styles from '../styles/components/Text.module.css';
import _ from 'lodash';

/**
 * Important: Custom fonts don't have all variants:
 * - Sofia only comes in bold
 * - Larsseit only comes in regular
 * - Only Lato has italic version
 * It will always fallback to Lato in case where a font doesn't have a requested version
 */
export interface TextProps extends Omit<React.HTMLProps<HTMLDivElement>, 'size' | 'style'> {
  font?: 'lato' | 'larsseit' | 'sofia';
  size?: 'normal' | 'big' | 'small';
  align?: 'left' | 'right' | 'center' | 'justify';
  linksBlack?: boolean; // Makes link appear the same color as other text, only underlined
  linksUnderline?: 'always' | 'hover' | 'notHover' | 'none';
}

const Text: React.FC<TextProps> = ({
  font = 'lato',
  size = 'normal',
  align = 'left',
  linksBlack = false,
  linksUnderline = 'always',
  className,
  children,
}) => {
  return (
    <div className={[
      styles.text,
      font !== 'lato' ? styles[font] : '',
      styles[size],
      linksBlack ? styles.linksBlack : '',
      styles['linksUnderline' + _.capitalize(linksUnderline)],
      styles[align],
      className,
    ].join(' ')}>
      {children}
    </div>
  );
};

export default Text;
