import React from 'react';
import styles from '../styles/pages/ThankYou.module.css';
import SignupPage from './SignupPage';
import logo from '../assets/logo-transparent-bg.svg';
import Text from '../components/Text';

const InTouch: React.FC = () => {
  return (
    <SignupPage
      className={styles.alreadyConnected}
      title="Thanks!"
      top={<img src={logo} alt="NetBlazr logo" aria-hidden={true}/>}
      centerVertically={true}
      isChatShown={false}
    >
      <Text font="larsseit" size="big" align="center" className={styles.content} linksBlack linksUnderline="none">
        <p>We’ll be in touch once service is available!</p>
        <p>
          <a href="/" target="_top" className={styles.returnLink}>Return to site</a>
        </p>
      </Text>
    </SignupPage>
  );
};

export default InTouch;
