import React, { useState } from 'react';
import _ from 'lodash';
import { useField, FieldAttributes } from 'formik';
import styles from '../../styles/components/form/TextField.module.css';

export interface TextFieldProps {
  label?: string;
  placeholder?: string;
  bold?: boolean;
}

const TextField: React.FC<FieldAttributes<TextFieldProps>> = props => {
  const [field, meta] = useField(props);
  const [value, setValue] = useState<string>('');
  const {
    label,
    placeholder,
    bold,
    ...inputProps
  } = props;
  const withError = meta.touched && meta.error;
  return (
    <div className={styles.field}>
      <label className={styles.label} htmlFor={field.name}>
        {label || (!_.isEmpty(value) ? placeholder : '')}&nbsp;
      </label>
      <input
        className={[
          withError ? styles.inputError : styles.inputNormal,
          bold ? styles.bold : '',
          props.disabled && !bold ? styles.grey : '',
        ].join(' ')}
        placeholder={placeholder || ''}
        {...field}
        onChange={e => {
          setValue(e.target.value);
          field.onChange(e);
        }}
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        {...(inputProps as any)}
      />
      <div className={styles.error}>{withError ? meta.error : ''}&nbsp;</div>
    </div>
  );
};

export default TextField;
