import React, { useEffect, useState } from 'react';
import styles from '../styles/components/ScheduleWindowPicker.module.css';
import { AppointmentWindow, OneDayTimeSlot } from '../model/schedule';

export interface ScheduleWindowPickerProps {
  day?: OneDayTimeSlot;
  onSelect: (window?: AppointmentWindow) => void;
}

export function toUSTime(hour: number): string {
  if (hour === 0) {
    return '12am';
  } else if (hour === 12) {
    return '12pm'
  } else {
    return hour < 12 ? `${hour}am` : `${hour - 12}pm`;
  }
}

function windowToString(window: AppointmentWindow): string {
  return `${toUSTime(window.start)}-${toUSTime(window.end)}`
}

const ScheduleWindowPicker: React.FC<ScheduleWindowPickerProps> = ({
  day,
  onSelect,
}) => {
  const [selectedIdx, setSelectedIdx] = useState<number>();

  useEffect(() => {
    if (day) {
      setSelectedIdx(undefined);
      onSelect(undefined)
    }
  }, [day, onSelect]);

  if (!day) {
    return (
      <div className={styles.pickerWrapper}>
        Please select a day.
      </div>
    );
  }

  if (day.slots.length === 0) {
    return (
      <div className={styles.pickerWrapper}>
        No time slots available for this date.
      </div>
    );
  }

  return (
    <div className={styles.pickerWrapper}>
      <div className={styles.picker}>
        {day.slots.map((w, idx) => (
          <div
            key={idx}
            className={
              selectedIdx === idx
                ? styles.windowChosen
                : w.available ? styles.windowAvailable : styles.windowUnavailable
            }
            onClick={() => {
              if (w.available) {
                if (selectedIdx === idx) {
                  setSelectedIdx(undefined);
                  onSelect(undefined);
                } else {
                  setSelectedIdx(idx);
                  onSelect(w);
                }
              }
            }}
          >
            {windowToString(w)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScheduleWindowPicker;
