import { api } from './client';
import {
  CreateAccountRequest,
  AccountWaitListContactInfo,
  CreateAccountResponse, BillingRequest,
} from '../model/account';

export async function createAccount(request: CreateAccountRequest): Promise<CreateAccountResponse> {
  return api<CreateAccountResponse>('/account/create', {
    method: 'POST',
    data: JSON.stringify(request),
  });
}

export async function saveWaitListContactInfo(request: AccountWaitListContactInfo): Promise<void> {
  return api<void>('/account/waitlist', {
    method: 'POST',
    data: JSON.stringify(request),
  });
}

export async function setBillingInfo(billing: BillingRequest): Promise<void> {
  return api<void>('/account/billing-info/create', {
    method: 'POST',
    data: JSON.stringify(billing),
  });
}
