import React from 'react';
import styles from '../styles/pages/ComingSoon.module.css';
import SignupPage from './SignupPage';
import Text from '../components/Text';
import {WaitListForm} from '../components/form/WaitListForm';
import {getAddressInfo} from '../helpers/store';
import {useHistory} from 'react-router-dom';
import {paths} from '../App';
import {AccountStatus} from "../model/AccountStatus";

const ComingSoon: React.FC = () => {
  const history = useHistory();
  const addressInfo = getAddressInfo();
  return (
    <SignupPage
      className={styles.comingSoon}
      title="Service Coming Soon!"
      centerVertically={true}
      isChatShown={false}
    >
      <Text font="larsseit" size="big" align="center" className={styles.content}>
        <p>
          We're currently in the process of installing service in your building!<br />
          Join our waitlist and we’ll notify you when service is live.
        </p>
      </Text>
      <WaitListForm
        addressInfo={addressInfo}
        apartment={''}
        accountStatus={AccountStatus.LeadServiceable}
        onNext={() => {
          history.push(paths.thankYou);
        }}
      />
    </SignupPage>
  );
};

export default ComingSoon;
