import React, { useState, useEffect } from "react";
import styles from "../../styles/components/form/ContactInfoForm.module.css";
import { Formik } from "formik";
import * as Yup from "yup";
import Button from "./Button";
import TextField from "./TextField";
import DropdownField, { DropdownOption } from "./DropdownField";
import AutosuggestField from "./AutosuggestField";
import { createAccount } from "../../rest/account";
import { CreateAccountRequest, FullAccountInfo, ServiceableAddress } from "../../model/account";
import {
  getServicePlan, setAccountDetails,
  setSonarAccountInfo,
} from "../../helpers/store";
import { getErrorMessage } from "../../helpers/error";
import Message from "../Message";
import _ from "lodash";
import { AddressInfo } from "../../model/address";
import { getApartmentsForAddress } from "../../rest/address";

export interface ContactInfoFormProps {
  addressInfo: AddressInfo;
  apartment?: string;
  onNext: () => void;
  onBack: () => void;
}

export const foundFromOptions: DropdownOption[] = [
  { value: "FriendNeighbor", text: "Friend/ Neighbor" },
  { value: "BuildingStaff", text: "Building Staff" },
  { value: "FlyerInMyBuilding", text: "Flyer in my Building" },
  { value: "MoveInPacket", text: "Move-in-Packet" },
  { value: "Event", text: "Event" },
  { value: "Email", text: "Email" },
  { value: "NewsBlog", text: "News/Blog" },
  { value: "OnlineSearch", text: "Online Search" },
  { value: "Mail", text: "Mail" },
  { value: "SocialMedia", text: "Social Media" },
  { value: "WebpassTransition", text: "Webpass Transition" },
];

export interface ContactInfoFormValues {
  address: string;
  apartment: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  foundFrom: string;
}

export const addressSchema = Yup.string()
  .trim()
  .required("Address is required");
export const firstNameSchema = Yup.string()
  .trim()
  .required("First name is required");
export const lastNameSchema = Yup.string()
  .trim()
  .required("Last name is required");
export const phoneNumberSchema = Yup.string()
  .trim()
  .required("Phone is required");
export const emailAddressSchema = Yup.string()
  .trim()
  .email("Provide a valid email address")
  .required("Email is required");
export const foundFromSchema = Yup.string()
  .trim()
  .oneOf(
    foundFromOptions.map((o) => o.value),
    "Please select a value from the list"
  )
  .required("Source of information is required");

const contactInfoFormSchema = Yup.object<ContactInfoFormValues>().shape({
  address: addressSchema,
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  phoneNumber: phoneNumberSchema,
  emailAddress: emailAddressSchema,
  foundFrom: foundFromSchema,
});

export const ContactInfoForm: React.FC<ContactInfoFormProps> = ({
  addressInfo,
  onBack,
  onNext,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [apartmentItems, setApartmentItems] = useState<Array<ServiceableAddress>>([]);

  useEffect(() => {
    async function load(address: AddressInfo) {
      try {
        setLoading(true);
        const res = await getApartmentsForAddress(address);
        setApartmentItems(res);
      } catch (e) {
        setError(getErrorMessage(e));
      } finally {
        setLoading(false);
      }
    }
    if (addressInfo) {
      load(addressInfo);
    }
  }, [addressInfo]);
  const getSuggestionValueForApartment = (s: ServiceableAddress) => {
    if (
      s.unitNumber === undefined ||
      s.unitNumber === null ||
      s.unitNumber.trim() === '') {
      return 'N/A';
    } else {
      return s.unitNumber;
    }
  };
  const renderSuggestionForApartment = (s: ServiceableAddress) => {
    return getSuggestionValueForApartment(s)
  };

  const validateApartment = (value: string) => {
    console.log('validate');
    let error;
    const apartmentMatches = apartmentItems.filter(
      (a) => {
        const v = getSuggestionValueForApartment(a)
         return v === value
        }
    );
    console.log(value)
    console.log(apartmentMatches)
    if (!value || !apartmentMatches || apartmentMatches.length === 0) {
      error = "Select valid apartment # from the list";
    }
    return error;
  };

  const findApartmentBySuggestionInput = (
    suggestionInput: string
  ): ServiceableAddress | undefined => {
    return apartmentItems.find((a) => {
      const v = getSuggestionValueForApartment(a);
      return v === suggestionInput
    });
  };

  const initialValues = {
    address: addressInfo?.addressString || "",
    apartment: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    foundFrom: "",
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={contactInfoFormSchema}
      onSubmit={async (values, { setSubmitting }) => {
        const servicePlan = getServicePlan();
        if (!servicePlan) {
          setError("No service plan selected")
          return;
        }
        const apartment = findApartmentBySuggestionInput(values.apartment);
        if (!apartment) {
          setError("Please fill the form");
          return;
        }
        setSubmitting(true);
        try {
          const accountDetails: CreateAccountRequest = {
            addressInfo: addressInfo,
            accountInfo: {
              firstName: _.trim(values.firstName),
              lastName: _.trim(values.lastName),
              phoneNumber: _.trim(values.phoneNumber),
              emailAddress: _.trim(values.emailAddress),
            } as FullAccountInfo,
            // apartment: values.apartment && !_.isEmpty(_.trim(values.apartment)) ? _.trim(values.apartment) : undefined,
            apartment: apartment,
            foundFrom: values.foundFrom,
            serviceCode: servicePlan.internalCode
          };
          const sonarAccountInfo = await createAccount(accountDetails);
          setSubmitting(false);
          setSonarAccountInfo(sonarAccountInfo);
          setAccountDetails(accountDetails);
          onNext();
        } catch (e) {
          setError(getErrorMessage(e));
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className={styles.contactInfoForm}>
          <TextField name="address" label="Your Address" disabled bold />
          <div className={styles.equalRow}>
            <TextField name="firstName" label="First Name" />
            <TextField name="lastName" label="Last Name" />
          </div>
          <div className={styles.equalRow}>
            <AutosuggestField
              name="apartment"
              label="Apartment #"
              items={apartmentItems}
              validate={validateApartment}
              getSuggestionValue={getSuggestionValueForApartment}
              renderSuggestion={renderSuggestionForApartment}
            />
            <TextField name="phoneNumber" label="Phone" type="tel" />
          </div>
          <TextField name="emailAddress" label="Email" type="email" />
          <DropdownField
            name="foundFrom"
            label="How did you find out about netBlazr?"
            placeholder="Please select"
            options={foundFromOptions}
          />
          {error && (
            <Message size="small" type="error">
              {error}
            </Message>
          )}
          <div className={styles.buttons}>
            <Button
              variant="normal"
              disabled={isSubmitting || !addressInfo}
              onClick={() => onBack && onBack()}
            >
              Back
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting || loading}
            >
              Next
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
