import React from 'react';
import styles from '../styles/pages/Connect.module.css';
import thankYouStyles from '../styles/pages/ThankYou.module.css';
import StepperPage from './StepperPage';
import Text from '../components/Text';
import logo from '../assets/logo-transparent-bg.svg';

const ConnectThankYou: React.FC = () => {
  return (
    <StepperPage
      step="complete"
      className={styles.connectThankYou}
      top={<img src={logo} alt="NetBlazr logo" aria-hidden={true} className={styles.smallLogo}/>}
      centerVertically={true}
      title="Thanks!"
      isChatShown={true}
    >
      <Text font="larsseit" size="big" align="center">
        <p>We’re excited to have you on board!</p>
      </Text>
      <Text font="lato" size="big" linksBlack linksUnderline="none" className={styles.thankYouText}>
        <p>
          To make any changes to your installation or your internet plan, give us a call on:
        </p>
        <p>
          <strong>617.718.5500x1</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          Or email us at:
        </p>
        <p>
          <strong>
            <a href="mailto:sales@netblazr.com">sales@netblazr.com</a>
          </strong>
        </p>
      </Text>
      <Text font="larsseit" size="big" align="center" linksBlack linksUnderline="none">
        <p>
          <a href="/" target="_top" className={thankYouStyles.returnLink}>Return to site</a>
        </p>
      </Text>
    </StepperPage>
  );
};

export default ConnectThankYou;
